@keyframes load8Keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-loading-wrp {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loader {
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin: 8rem auto;
  font-size: 0.25rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #5f39f833;
  border-right: 1.1em solid #5f39f833;
  border-bottom: 1.1em solid #5f39f833;
  border-left: 1.1em solid #5f39f8;
  transform: translateZ(0);
  animation: load8Keyframes 1.1s infinite linear;
}
